type Props = {
  Icon?: any,
  title?: string,
  values?: {
    title?: string,
  }[],
};

export default function FooterContact(props: Props) {
  const { Icon, title, values } = props;
  return (
    <div className="w-full flex flex-row mt-10px items-center">
      <Icon fill="#fff" className="w-18px h-18px mr-10px" />
      <div className="flex flex-col">
        <div className="text-white text-11 xl:text-13 font-text">{title}</div>
        {values?.map((item, index) => {
          return (
            <div
              className="text-white font-textBold font-bold text-11 xl:text-13 mt-4px"
              key={index}>
              {item.title}
            </div>
          );
        })}
      </div>
    </div>
  );
}
